<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item['@id']}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />

    <div v-if="item" class="table-enigme-show">
      <v-simple-table>
        <template slot="default">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>

              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>{{ $t('fileImage') }}</strong></td>
              <td>
                                    {{ item['fileImage'] }}
              </td>
            
              <td><strong>{{ $t('slug') }}</strong></td>
              <td>
                                    {{ item['slug'] }}
              </td>
            </tr>
            
            <tr>
              <td><strong>{{ $t('texte') }}</strong></td>
              <td>
                                    {{ item['texte'] }}
              </td>
            
              <td><strong>{{ $t('indice') }}</strong></td>
              <td>
                                    {{ item['indice'] }}
              </td>
            </tr>
            
            <tr>
              <td><strong>{{ $t('intro') }}</strong></td>
              <td>
                                    {{ item['intro'] }}
              </td>
            
              <td><strong>{{ $t('image') }}</strong></td>
              <td>
                                    {{ item['image'] }}
              </td>
            </tr>
            
            <tr>
              <td><strong>{{ $t('titre') }}</strong></td>
              <td>
                                    {{ item['titre'] }}
              </td>
            
              <td></td>
            </tr>
            
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';

const servicePrefix = 'Enigme';

export default {
  name: 'EnigmeShow',
  servicePrefix,
  components: {
      Loading,
      Toolbar
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields('enigme', {
      isLoading: 'isLoading'
    }),
    ...mapGetters('enigme', ['find'])
  },
  methods: {
    ...mapActions('enigme', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load'
    })
  }
};
</script>
